
































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import PdfPreview from "@/components/PdfPreview.vue";
import { ICOA } from "@/interfaces";
import {
  dispatchGetCOAPdf,
  dispatchGetCOAObject,
  dispatchUpdateCOA,
  dispatchGetQRCodeImage,
} from "@/store/main/actions";
import { readHasAdminAccess } from "@/store/main/getters";

const STATUS = {
  LOADING: 1,
  SUCCESS: 2,
  FAILURE: 3,
  HIDDEN: 4,
};

const tooltipCopyMessages = {
  ready: "Copy to clipboard",
  copied: "Copied!",
};

@Component({
  components: {
    PdfPreview,
  },
})
export default class CoaDetails extends Vue {
  pdfData: Uint8Array | null = null;
  loading = false;
  qrcode: File | null = null;
  qrcodeDialog = false; // show the v-dialog to download qr code
  coa: ICOA | null = null;
  publishStatus: number = STATUS.HIDDEN; // used to show success or failure of publish/unpublish checkbox
  tooltipCopyMsg = tooltipCopyMessages.ready;
  version: number | null = null;
  editingName = false;
  coaName = "loading...";
  coaNameStatus: number = STATUS.LOADING;
  pdfFilename = "COA.pdf";

  get publishUpdating() {
    return !this.loading && this.publishStatus === STATUS.LOADING;
  }

  get publishSuccess() {
    return !this.loading && this.publishStatus === STATUS.SUCCESS;
  }

  get publishFailure() {
    return !this.loading && this.publishStatus === STATUS.FAILURE;
  }

  get coaNameUpdating() {
    return !this.loading && this.coaNameStatus === STATUS.LOADING;
  }

  get coaNameSuccess() {
    return !this.loading && this.coaNameStatus === STATUS.SUCCESS;
  }

  get isQRCodeValid() {
    return this.qrcode !== null;
  }

  get published() {
    if (this.coa) {
      return this.coa.published;
    } else {
      return true;
    }
  }

  set published(val: boolean) {
    this.publishStatus = STATUS.LOADING;
    this.updateCOAPublished(val);
  }

  get coaUrl() {
    if (this.coa && this.coa.public_url) {
      return this.coa.public_url;
    } else {
      return "";
    }
  }

  get qrcodeUrl() {
    return this.qrcode ? URL.createObjectURL(this.qrcode) : "";
  }

  get publishedToolTip() {
    return this.published
      ? "This link is publicly accessible."
      : "This COA is not published. The link will not work until you make it publicly accessible.";
  }

  get archivedVersions() {
    let current = this.coa?.version;
    let versions: { value: number; text: string }[] = [];
    if (current) {
      while (current > 0) {
        versions.push({ value: current, text: `Version ${current}` });
        current -= 1;
      }
      versions[0].text = versions[0].text + " (latest)";
      this.version = versions[0].value;
    }
    return versions;
  }

  get isAdmin() {
    return readHasAdminAccess(this.$store);
  }

  get isArchived() {
    return this.version !== this.coa?.version;
  }

  get showPublishCheckBox() {
    return this.isAdmin ? !this.loading && !this.isArchived : !this.loading;
  }

  @Watch("version")
  async versionChanged() {
    if (this.archivedVersions.length > 0 && !this.loading) {
      this.loading = true;
      this.pdfData = null;
      await this.getPDFData();
      this.loading = false;
    }
  }

  async showQRCode() {
    await this.createQRCode();
    this.qrcodeDialog = true;
  }

  async refreshData() {
    this.loading = true;
    await this.getCOAObject();
    await this.getPDFData();
    this.loading = false;
  }

  async getPDFData() {
    const pdfResponse = await dispatchGetCOAPdf(this.$store, {
      id: +this.$route.params.coaId,
      version: this.isArchived && this.isAdmin ? this.version : null,
    });

    this.pdfData = pdfResponse
      ? new Uint8Array(await pdfResponse.data.arrayBuffer())
      : null;
    if (pdfResponse?.headers["content-disposition"]?.includes("filename=")) {
      this.pdfFilename = pdfResponse.headers["content-disposition"]
        .split("filename=")[1]
        .replaceAll('"', "");
    } else {
      this.pdfFilename = "COA.pdf";
    }
  }

  async getCOAObject() {
    const coaResponse = await dispatchGetCOAObject(this.$store, {
      id: +this.$route.params.coaId,
    });

    this.coa = coaResponse ? coaResponse.data : null;
    if (coaResponse?.data?.name) {
      this.coaName = coaResponse.data.name;
      this.coaNameStatus = STATUS.SUCCESS;
    }
  }

  async updateCOAName() {
    if (this.coa && this.coa.name !== this.coaName) {
      this.coaNameStatus = STATUS.LOADING;
      this.coa.name = this.coaName;
      const response = await dispatchUpdateCOA(this.$store, this.coa);
      if (response) {
        this.coa = response.data;
        this.coaNameStatus = STATUS.SUCCESS;
        // Send event to ResultDetails so we can change the name in the sidebar
        this.$emit("coa-name-changed");
      } else {
        // Set the coa name back to original
        this.coaName = this.coa.name;
      }
    }
    this.editingName = false;
  }

  async updateCOAPublished(published: boolean) {
    if (this.coa) {
      this.coa.published = published;
      const response = await dispatchUpdateCOA(this.$store, this.coa);
      if (response) {
        this.coa = response.data;
        this.showPublishSuccess();
      } else {
        this.publishStatus = STATUS.FAILURE;
      }
    }
  }

  showPublishSuccess() {
    this.publishStatus = STATUS.SUCCESS;
    setTimeout(() => {
      this.publishStatus = STATUS.HIDDEN;
    }, 1000);
  }

  public async mounted() {
    await this.refreshData();
  }

  public beforeRouteUpdate(to, _from, next) {
    next();
    this.pdfData = null;
    this.coa = null;
    this.refreshData();
  }

  async downloadPDF() {
    if (!this.loading && this.pdfData) {
      const blob = new Blob([this.pdfData], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", this.pdfFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  async downloadQRCode() {
    if (!this.loading && this.qrcode) {
      const qrcodeUrl = URL.createObjectURL(this.qrcode);
      const link = document.createElement("a");
      link.href = qrcodeUrl;
      let uuid = this.coa?.uuid ? this.coa.uuid : this.$route.params.id;
      link.setAttribute("download", `QR_${uuid}.png`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  async createQRCode() {
    let response = await dispatchGetQRCodeImage(this.$store, {
      id: +this.$route.params.coaId,
    });

    if (response) {
      this.qrcode = response.data ? response.data : null;
    }
  }

  copyUrl() {
    let text = this.coaUrl;
    navigator.clipboard.writeText(text);

    this.tooltipCopyMsg = tooltipCopyMessages.copied;
    setTimeout(() => {
      this.tooltipCopyMsg = tooltipCopyMessages.ready;
    }, 1000);
  }
}
